import i18n from '@/assets/i18n/index.js'
import { CrosshairMode, LineStyle, ColorType} from 'lightweight-charts';
import { formatTooltipDate } from "@/utils/date.js"
const { t } = i18n.global;

export const createTooltip = () => {
    const toolTip = document.createElement("div");
    toolTip.style = `width: auto; height: 70px; position: absolute; display: none;
 padding: 8px; box-sizing: border-box; border-radius: 4px; box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px; transition: opacity 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, visibility 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0s, transform 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s; left: 0px; transform: translate3d(294px, 66px, 0px); border-color: rgb(255, 255, 255);
 font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; font-family: 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
    toolTip.style.background = "white";
    toolTip.style.color = "black";
    return toolTip
}
export const chartTooltip = (param, chartContainer, series, graph, toolTip, pageName, currency) => {
    const toolTipWidth = 200;
    const toolTipHeight = 100;
    const toolTipMargin = 5;
    const maximumFractionDigits = currency && currency.toUpperCase() === 'BTC' ? 8 : 4
    if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > chartContainer.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainer.clientHeight
    ) {
        toolTip.style.display = "none";
    } else {
        const dateStr = formatTooltipDate(new Date(param.time) * 1000, graph);
        const value = param.seriesData.get(series);
        toolTip.style.display = "block";
        toolTip.innerHTML = `
          <div style="font-size:14px;color:#666;font-weight:400;line-height:1;margin:4px;">
                ${dateStr}
          </div>
          <div style="margin:8px;">
              <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${pageName === 'price_charts' ? '#198754' : '#0d6efd'};"></span>
              <span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">
                ${t(`${pageName}.${graph.replace("gf_", "")}`)}
              </span>
              <span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">
                ${value.value.toLocaleString(undefined, { maximumFractionDigits })}
              </span>
          </div>`;

        const coordinate = series.priceToCoordinate(value);
        let shiftedCoordinate = param.point.x - 275;
        if (coordinate === null) {
            return;
        }
        shiftedCoordinate = Math.max(
            0,
            Math.min(
                chartContainer.clientWidth - toolTipWidth,
                shiftedCoordinate
            )
        );
        const coordinateY =
            coordinate - toolTipHeight - toolTipMargin > 0
                ? coordinate - toolTipHeight - toolTipMargin
                : Math.max(
                    0,
                    Math.min(
                        chartContainer.clientHeight -
                        toolTipHeight -
                        toolTipMargin,
                        coordinate + toolTipMargin
                    )
                );
        toolTip.style.left = shiftedCoordinate + "px";
        toolTip.style.top = coordinateY + "px";
    }

}
export const valueFormatter = (graph, currency) => {
    let options;
    switch (graph) {
        case 'gf_market_price':
            options = {
                style: "currency",
                currency: currency ?? 'USD',
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: currency === 'BTC' ? 8 : 2,
                ...(currency === 'BTC') && { maximumSignificantDigits: 4 }
            }
            break;
        case 'gf_price_usd':
            options = {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 4,
            }
            break;
        case 'gf_output_value_per_day':
        case 'gf_estimated_txn_value':
        case 'gf_market_capitalization':
        case 'gf_exchange_trade_volume':
            options = {
                style: "currency",
                currency: currency ?? 'USD',
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 2,
                notation: 'compact'
            }
            break;
        case 'gf_blockchain_size':
            options = {
                style: "unit",
                unit: "terabyte",
                maximumFractionDigits: 2
            }
            break;
        case 'gf_avg_ledger_size':
            options = {
                style: "unit",
                unit: "megabyte",
                maximumFractionDigits: 2
            }
            break;
        case 'gf_avg_ledger_time':
            options = {
                style: "unit",
                unit: "second",
                maximumFractionDigits: 2
            }
            break;
        default:
            options = {
                notation: 'compact',
                maximumFractionDigits: 2
            }
            break;
    }
    return new Intl.NumberFormat(undefined, options).format;
}

export const barChartOptions = { layout: { textColor: 'black', background: { type: 'solid', color: 'white' } } };
export const chartOptions = {
    width: 0,
    height: 0,
    layout: {
        background: {
          color: "rgb(255,0,0,0)",
          type: ColorType.Gradient,
        },
        textColor: 'gray',
    },
    leftPriceScale: {
        borderVisible: true,
        borderColor: "#D6DCDE",
        visible: true,
        mode: 1,
        drawTicks: false
    },
    rightPriceScale: {
        visible: false,
    },
    crosshair: {
        mode: CrosshairMode.Normal,
        horzLine: {
            labelVisible: true,
            style: LineStyle.Dotted,
            labelBackgroundColor: 'rgb(108, 117, 125)',
        },
        vertLine: {
            labelVisible: true,
            style: LineStyle.Dotted,
            labelBackgroundColor: 'rgb(108, 117, 125)'
        },
    },
    grid: {
        vertLines: {
            visible: false,
        },
        horzLines: {
            visible: true,
        },
    },
    timeScale: { borderVisible: true, borderColor: "#D6DCDE" },
};
export const seriesOptions = {
    color: "#0d6efd",
    lastValueVisible: true,
    priceLineVisible: true,
};
export const priceSeriesOptions = {
    color: "#198754",
    lastValueVisible: false,
    priceLineVisible: false,
};
export const avgLineValue = (avgValue) => ({
    price: avgValue,
    color: 'rgb(108, 117, 125)',
    lineWidth: 1,
    lineStyle: LineStyle.Dotted,
    axisLabelVisible: true,
    title: 'avg',
});
export const resolutions = [
    { text: "1M", resolution: 60000 },
    { text: "5M", resolution: 300000 },
    { text: "15M", resolution: 900000 },
    { text: "1H", resolution: 3600000 },
    { text: "1D", resolution: 86400000 },
    { text: "1W", resolution: 604800000 },
];
export const candleStickChartOptions = {
    height: 360,
    layout: {
        background: {
          color: "rgb(255,0,0,0)",
          type: ColorType.Gradient,
        }
    },
    grid: {
      vertLines: {
        color: "rgba(42, 46, 57, 0.1)",
      },
      horzLines: {
        color: "rgba(42, 46, 57, 0)",
      },
    },
    crosshair: {
      mode: CrosshairMode.Normal,
    },
    priceScale: {
         borderColor: "rgba(42, 46, 57, 0.1)",
    },
    timeScale: {
        borderColor: "rgba(42, 46, 57, 0.1)",
        timeVisible: true,
        fixRightEdge: true
    },
};

export const scaleOptions = {
    scaleMargins: {
      top: 0.9,
      bottom: 0,
    },
}
export const volumeSeriesOptions = {
    priceScaleId: "",
    lineWidth: 2,
    priceFormat: {
      type: "volume",
    },
    overlay: true,
    ...scaleOptions,
};
